*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --background-color: #121212;
  --text-color: #ffffff;
  --secondary-text-color: #bbbbbb;
  --primary-color: #1e88e5;
  --badge-js: #f7df1e;
  --badge-html: #e34c26;
  --badge-python: #306998;
  --badge-node: #68a063;
  --badge-java: #b07219;
  --badge-delphi: #ee1f35;
  --link-bg: #1e1e1e;
  --link-bg-hover: #2a2a2a;
  --tooltip-bg: rgba(0, 0, 0, 0.8);
  --tooltip-text: #fff;
  --light-color: rgba(50, 50, 50, 0.3);
}
body.light {
  --background-color: #f0f0f0;
  --text-color: #121212;
  --secondary-text-color: #555555;
  --link-bg: #e0e0e0;
  --link-bg-hover: #d0d0d0;
  --tooltip-bg: rgba(0, 0, 0, 0.8);
  --badge-js: #fff4b1;
  --badge-html: #f2a194;
  --badge-python: #80b2e6;
  --badge-node: #a6d9a0;
  --badge-java: #d9b38c;
  --badge-delphi: #f5a1ac;
  --light-color: rgba(0, 0, 0, 0.3);
}
body {
  background: radial-gradient(at top left, var(--light-color), transparent 50%),
    radial-gradient(at bottom right, var(--light-color), transparent 50%),
    var(--background-color);
  color: var(--text-color);
  font-family: "Nunito", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  transition: background 0.5s, color 0.5s;
}
.container {
  margin: 0 auto;
  width: 90%;
  max-width: 800px;
  padding: 20px;
}
.header {
  text-align: center;
  margin-bottom: 30px;
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid var(--text-color);
  transition: transform 0.3s, border-color 0.3s;
}
body.light .profile-pic {
  border-color: var(--text-color);
}
.profile-pic:hover {
  transform: scale(1.05);
}
.info h1 {
  font-size: 2em;
  margin-bottom: 8px;
}
.info p {
  font-size: 1em;
  color: var(--secondary-text-color);
}
.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 30px;
}
.badge {
  background-color: #2c2c2c;
  color: #ffffff;
  border-radius: 20px;
  font-size: 0.9em;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  transition: background-color 0.3s, transform 0.3s;
}
body.light .badge {
  color: #121212;
}
.badge:hover {
  transform: scale(1.05);
}
.badge i {
  font-size: 1.1em;
}
.badge--js {
  background-color: var(--badge-js);
}
.badge--html {
  background-color: var(--badge-html);
}
.badge--python {
  background-color: var(--badge-python);
}
.badge--node {
  background-color: var(--badge-node);
}
.badge--java {
  background-color: var(--badge-java);
}
.badge--delphi {
  background-color: var(--badge-delphi);
}
.badge::after,
.social-link::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  padding: 4px 8px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  font-size: 0.8em;
  z-index: 100;
}
.badge:hover::after,
.social-link:hover::after {
  opacity: 1;
}
.links {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-bottom: 30px;
}
@media (min-width: 600px) {
  .links {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .links {
    grid-template-columns: repeat(4, 1fr);
  }
}
.link-item {
  background-color: var(--link-bg);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  min-width: 180px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
  position: relative;
  overflow: hidden;
}
.link-item:hover {
  background-color: var(--link-bg-hover);
  transform: translateY(-5px);
}
.link-item h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2em;
  margin-bottom: 8px;
}
.link-item h2 i {
  font-size: 1.2em;
}
.link-item p {
  font-size: 0.9em;
  color: var(--secondary-text-color);
}
.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
.social-link {
  background-color: var(--link-bg);
  color: var(--text-color);
  padding: 10px 14px;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  border: none;
  cursor: pointer;
}
.social-link:hover {
  background-color: var(--link-bg-hover);
  transform: translateY(-5px);
}
.footer {
  text-align: center;
  font-size: 0.8em;
  color: var(--secondary-text-color);
}
.toggle-theme {
  position: fixed;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: 1.5em;
  z-index: 10;
  transition: transform 0.3s;
}
.toggle-theme:hover {
  transform: rotate(20deg);
}
.hidden {
  display: none;
}
.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 0.95em;
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.popup.show {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}
.popup.hidden {
  opacity: 0;
  transform: translateY(20px);
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 600px) {
  .profile-pic {
    width: 90px;
    height: 90px;
  }
  .info h1 {
    font-size: 1.5em;
  }
  .badges {
    gap: 8px;
  }
  .badge {
    font-size: 0.8em;
    padding: 4px 10px;
  }
  .link-item h2 {
    font-size: 1em;
  }
  .link-item p {
    font-size: 0.8em;
  }
  .social-link {
    font-size: 1.2em;
  }
  .links {
    grid-template-columns: 1fr;
  }
}
